import { BrowserRouter, Routes, Route } from "react-router-dom";
import "es6-shim";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import useWindowSize from "hooks/useWindowResize";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import SectionGridHasMap from "containers/ListingStayPage/SectionGridHasMap";
import PageResources from "containers/PageResources/PageResources";
import PageMentorConnect from "containers/PageMentorConnect/PageMentorConnect";
import PageMaintenance from "containers/PageMaintenance/PageMaintenance";
import TermsAndConditions from "containers/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "containers/PrivacyPolicy/PrivacyPolicy";
import SearchResultsScreen from "components/SearchResultsScreen";
import Header from "components/Header/Header";
import path, { basename } from "path";
import Sidebar from "components/Sidebar/Sidebar";
import PageYourApplications from "containers/PageYourApplications/PageYourApplications";
import { AuthenticationGuard } from "./AuthenticationGaurd";
import RentalApplicationForm from "components/RentalApplicationForm/RentalApplicationForm";
import PageUserDocuments from "containers/PageUserDocuments/PageUserDocuments";
import PageShortlist from "containers/PageShortlist/PageShortlist";
import PageInsurent from "containers/PageInsurent/PageInsurent";
import Banner from "components/Banner/Banner";
import MainSidebarMobile from "components/Sidebar/MainSidebarMobile";
import FloatingChatButton from "components/FloatingChatButton/FloatingChatButton";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome, isGaurded: false },
  { path: "/#", exact: true, component: PageHome, isGaurded: false },

  { path: "/listings", component: SectionGridHasMap, isGaurded: false },
  {
    path: "/listing-detail/:id",
    component: ListingStayDetailPage,
    isGaurded: false,
  },

  { path: "/applications", component: PageYourApplications, isGaurded: true},

  { path: "/connect", component: PageMentorConnect },
  { path: "/maintenance", component: PageMaintenance },
  { path: "/resources", component: PageResources },
  { path: "/about", component: PageAbout },
  { path: "/contact", component: PageContact },
  { path: "/documents", component: PageUserDocuments, isGaurded: true },
  { path: "/shortlist", component: PageShortlist, isGaurded: true },


  { path: "/terms-and-conditions", component: TermsAndConditions },
  { path: "/privacy-policy", component: PrivacyPolicy },
  //
  { path: "/search-results", component: SearchResultsScreen, isGaurded: false },

  { path: "/connect", component: PageMentorConnect, isGaurded: false },
  { path: "/resources", component: PageResources, isGaurded: false },
  { path: "/about", component: PageAbout, isGaurded: false },
  { path: "/contact", component: PageContact, isGaurded: false },
  { path: "/cosigner", component: PageInsurent, isGaurded: false },
  { path: "/contact", component: PageContact, isGaurded: false },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    isGaurded: false,
  },
  { path: "/privacy-policy", component: PrivacyPolicy, isGaurded: false },

  // Private Routes
  {
    path: "/listing-detail/:id/rental-application",
    component: RentalApplicationForm,
    isGaurded: true,
  },
];

const MyRoutes = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;

  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyBKAfYPRG77UvTMaEPEkl2_KvZeRKzuZlA",
    authDomain: "landing-app-99342.firebaseapp.com",
    databaseURL: "https://landing-app-99342-default-rtdb.firebaseio.com",
    projectId: "landing-app-99342",
    storageBucket: "landing-app-99342.appspot.com",
    messagingSenderId: "351329990839",
    appId: "1:351329990839:web:2f4f27133678d8db2eb532",
    measurementId: "G-H31TN4EELB",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return (
    <>
     <ScrollToTop />
      <Header
        className={"shadow-sm dark:border-b dark:border-neutral-700"}
        navType="MainNav1"
      />
      {/* <Banner /> */}
      <Sidebar className={"dark:border-b hidden md:block"} />
      <MainSidebarMobile className={"dark:border-b md:hidden"} />
      <Routes>
        {pages.map(({ component, path, isGaurded }) => {
          const Component = component;
          return (
            <Route
              key={path}
              element={
                isGaurded ? (
                  <AuthenticationGuard component={Component} />
                ) : (
                  <Component />
                )
              }
              path={path}
            />
          );
        })}
        <Route path="*" element={<Page404 />} />
      </Routes>

      <FloatingChatButton />

      <Footer />
    </>
  );
};

export default MyRoutes;