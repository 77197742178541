// src/components/FloatingButton.tsx
import React from "react";
import "./FloatingChatButton.css";
import chatIcon from "./chat-icon.png";

const FloatingChatButton: React.FC = () => {
  const phoneNumber = "18573813062";
  const message = "Hello! I'm interested in knowing more Students Landing services.";

  const chatLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <a
      href={chatLink}
      target="_blank"
      rel="noopener noreferrer"
      className="floating-button"
    >
      <img src={chatIcon} alt="Chat Icon" className="chat-icon" />
    </a>
  );
};

export default FloatingChatButton;
