import React, { ChangeEvent, InputHTMLAttributes, useEffect, useState } from "react";
import "./Input.css";
import { useListingSearchParams } from "providers/SearchParamsProvider";
import { useNavigate } from "react-router-dom";

export interface SearchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
}

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      className = "",
      fontClass = "text-sm font-normal",
      rounded = "rounded-3xl",
      children,
      type = "text",
      ...args
    },
    ref
  ) => {
    const { searchParams, setSearchParams } = useListingSearchParams();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>("");

    const handleSearchChange = (filterType: string, value: string) => {
      value = value.trimStart();

      if (value.length > 3) {
        setSearchParams({
          ...searchParams,
          [filterType]: searchParams[filterType] === value ? null : value,
        });
      } else if (value.length <= 3 && searchText.length > 3) {
        setSearchParams({
          ...searchParams,
          [filterType]: null,
        });
      }
      setSearchText(value);
    };

    const handleSearch = () => {
      navigate("/search-results");
    };

    return (
      <>
        <input
          placeholder={type}
          type="search"
          autoComplete="my-search-field"
          name="search-field"
          value={searchText}
          className={`inline-block relative focus:border-primary-green focus:ring focus:ring-primary-200 focus:ring-opacity-50 transition-all duration-500 bg-white bg-no-repeat bg-[30px] outline-none shadow focus:shadow-xl ${rounded} ${fontClass} ${className}`}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleSearchChange("address", e.target.value)
          }
          onClick={handleSearch}
        />
      </>
    );
  }
);

export default SearchInput;
