import React, { FC, useEffect, useRef, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import MobileFooterSticky from "./MobileFooterSticky";
import { useParams } from "react-router";
import { Attachment, AttachmentType, Property } from "data/models/property";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import GoogleMapReact from "google-map-react";
import formatCurrency from "../../utils/formatCurrency";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useCopyToClipboard } from "react-use";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ListingRepository from "data/repositories/ListingRepository";
import { Helmet } from "react-helmet";
import { useUserAccount } from "providers/UserAccountProvider";
import commingsoon from "images/graphics/comming_soon_image.png"
import { formatBathsDisplay, formatBedsDisplay } from "utils/bedbathFormatter";
import { useNavigate } from "react-router-dom";
import { useListingSearchParams } from "providers/SearchParamsProvider";
import { useAuth0 } from "@auth0/auth0-react";
import UserAccountRepository from "data/repositories/UserAccountRepository";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { universities } from "components/Header/UniversityModal";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { InlineWidget } from "react-calendly";
import NcModal from "shared/NcModal/NcModal";


export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const { isAuthenticated } =
    useAuth0();
  const { userAccount, setUserAccount } = useUserAccount();
  const { searchParams, setSearchParams } = useListingSearchParams();
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);


  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [property, setProperty] = useState<Property>();
  const [images, setImages] = useState<Attachment[]>();
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [value, copy] = useCopyToClipboard();
  const [buttonText, setButtonText] = useState("Share");
  const [drivingDistance, setDrivingDistance] = useState("");
  const [drivingDuration, setDrivingDuration] = useState("");
  const [walkingDuration, setWalkingDuration] = useState("");
  const mapContainer = useRef<HTMLDivElement>(null);
  let universityLatLong: any = [];

  mapboxgl.accessToken =
    "pk.eyJ1Ijoia3NoaXRpemoiLCJhIjoiY2xyYWY3MzR6MGNxdzJtcDF2Y2QyM3ptcyJ9.GGsccjbzarKNo1u0V8o8_Q";

  const navigate = useNavigate();

  if (searchParams.university !== null) {
    if (searchParams.university === "Northeastern University")
      universityLatLong = [Number(universities.Boston[0].address.lon), Number(universities.Boston[0].address.lat)];
    else if (searchParams.university === "Boston University")
      universityLatLong = [Number(universities.Boston[1].address.lon), Number(universities.Boston[1].address.lat)];
    else if (searchParams.university === "Harvard University")
      universityLatLong = [Number(universities.Boston[2].address.lon), Number(universities.Boston[2].address.lat)];
    else if (searchParams.university === "Massachusetts Institute of Technology")
      universityLatLong = [Number(universities.Boston[3].address.lon), Number(universities.Boston[3].address.lat)];
    else if (searchParams.university === "Umass Boston")
      universityLatLong = [Number(universities.Boston[4].address.lon), Number(universities.Boston[4].address.lat)];
  }

  useEffect(() => {
    setLoading(true)

    const fetchData = async () => {
      try {
        const listing = await ListingRepository.getInstance().getPropertyById(
          id!
        );
        setProperty(listing);
        setImages(
          listing?.attachments?.filter(
            (attachment) => attachment.type === AttachmentType.Image
          )!
        );
        if (searchParams.university !== null) {
          const map = new mapboxgl.Map({
            container: mapContainer.current,
            center: [listing?.address?.lon, listing?.address?.lat],
            style: "mapbox://styles/mapbox/streets-v10",
            zoom: 12,
          });

          map.on("load", () => {
            const direction = new MapboxDirections({
              accessToken: mapboxgl.accessToken,
              // profile: "mapbox/cycling",
              interactive: false,
              controls: {
                inputs: false,
                instructions: false,
                profileSwitcher: false,
              },
            });
            map.addControl(direction);
            direction.setOrigin([listing?.address?.lon, listing?.address?.lat]);
            direction.setDestination(universityLatLong);
          });

          axios.get(`https://api.mapbox.com/directions/v5/mapbox/driving/${listing?.address?.lon}%2C${listing?.address?.lat}%3B${universityLatLong[0]}%2C${universityLatLong[1]}?alternatives=false&geometries=geojson&overview=simplified&steps=false&access_token=pk.eyJ1Ijoia3NoaXRpemoiLCJhIjoiY2xyYWY3MzR6MGNxdzJtcDF2Y2QyM3ptcyJ9.GGsccjbzarKNo1u0V8o8_Q`).then((data) => {
            console.log("Driving duration: " + (data.data.routes[0].duration / 60).toFixed(2));
            console.log("Distance: " + (data.data.routes[0].distance / 1000).toFixed(2));
            setDrivingDistance((data.data.routes[0].distance / 1000).toFixed(2));
            setDrivingDuration((data.data.routes[0].duration / 60).toFixed(2));
          });

          axios.get(`https://api.mapbox.com/directions/v5/mapbox/walking/${listing?.address?.lon}%2C${listing?.address?.lat}%3B${universityLatLong[0]}%2C${universityLatLong[1]}?alternatives=false&geometries=geojson&overview=simplified&steps=false&access_token=pk.eyJ1Ijoia3NoaXRpemoiLCJhIjoiY2xyYWY3MzR6MGNxdzJtcDF2Y2QyM3ptcyJ9.GGsccjbzarKNo1u0V8o8_Q`).then((data) => {
            console.log("Walking duration: " + (data.data.routes[0].duration / 60).toFixed(2));
            setWalkingDuration((data.data.routes[0].duration / 60).toFixed(2));
          });

        }
      } catch (error) {
        console.log("error", error);
        navigate("/error");
      }

    };

    fetchData();
    setLoading(false)

  }, [userAccount, mapContainer]);

  // Keeping this as a comment because we might need the shortlist functionality on each listing in future.

  const handleShortlist = () => {
    if (isAuthenticated) shortlistTheItem(userAccount.id!);
    else alert("You need to log in first");
  }

  const shortlistTheItem = async (userId: string) => {
    const newShortListValue = !userAccount.likedListings?.some(
      (listing) => listing.id === id
    );

    let updatedShortListings;

    if (newShortListValue) {
      updatedShortListings = [...userAccount.likedListings!, property!];
    } else {
      updatedShortListings = userAccount.likedListings!.filter(
        (a) => a.id !== property!.id
      );
    }

    setUserAccount({ ...userAccount, likedListings: updatedShortListings });

    await UserAccountRepository.getInstance().shortlistListing(
      userId,
      property!.id!
    );
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const handleClick = () => {
    copy(window.location.href);
    setButtonText("Link Copied");
  };

  const renderTitleSection = () => {
    return (
      <div className="listingSection__wrap !space-y-6">

        {/* <div className="flex justify-between items-center">
          <LikeSaveBtns />
        </div> */}

        <div className="relative flex justify-between">
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {property && property?.address?.line1 && property?.address?.line1}
            {"  "}
            {property && property?.address?.line2 && property?.address?.line2}
          </h2>

          <p>
            <span className="text-primary-green">
              <span className="text-xl font-bold">
                {`${formatCurrency(property?.rent!)}`}
              </span>
            </span>
            / month
          </p>
        </div>

        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">
              {" "}
              {property
                ? `${property.address?.city}, ${property.address?.state}`
                : ""}
            </span>
            <span className="ml-3 text-sm border rounded-full p-2">
              {" "}
              {property
                ? `${property.address?.neighbourhood}`
                : ""}
            </span>
          </span>
        </div>

        <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />

        <div className="flex items-center justify-between xl:justify-start md:space-x-8 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="rounded-2xl border p-5 text-center">
            <div>
              <i className="las la-bed text-2xl"></i>
            </div>
            <div>
              <span>
                <span className=""> {formatBedsDisplay(property?.bed)} </span>
              </span>
            </div>
          </div>
          <div className="rounded-2xl border p-5 text-center">
            <div>
              <i className=" las la-bath text-2xl"></i>
            </div>
            <div>
              <span>
                <span className=""> {formatBathsDisplay(property?.bath)} </span>
              </span>
            </div>
          </div>
          {property?.area ? (
            <div className="rounded-2xl border p-5 text-center">
              <div>
                <i className="las la-expand-arrows-alt text-2xl"></i>
              </div>
              <div>
                <span>
                  <span className="">{property?.area}</span>{" "}
                  <span className="hidden sm:inline-block">Sq. Ft</span>
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="rounded-2xl border px-3 md:px-5 py-5 text-center">
            <div>
              <i className="las la-calendar text-2xl"></i>
            </div>
            <div>
              <span>
                <span className="">
                  {new Date(property?.availabilityDate!).toLocaleDateString(
                    "en-US",
                    {
                      month: "short", // "Jun"
                      day: "2-digit", // "01"
                      timeZone: "UTC",
                    }
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAmentiesSection = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Other Amenities </h2>
          {/* <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span> */}
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {property!.amenities!.map((item) => (
            <div key={item} className="flex items-center space-x-3">
              <span className=" ">{item}</span>
            </div>
          ))}
        </div>
        {/* <div>
          <ButtonSecondary>View more {property?.amenities?.length} amenities</ButtonSecondary>
        </div> */}
      </div>
    );
  };

  const renderLocationSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        {/* <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {property?.address?.city}, {property?.address?.state}
          </span>
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* MAP */}
        <div className="aspect-w-2 aspect-h-2 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            {
              searchParams.university !== null ?
                <div className="h-full" ref={mapContainer} />
                :
                property ?
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyBKAfYPRG77UvTMaEPEkl2_KvZeRKzuZlA",
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={15}
                    defaultCenter={{
                      lat: +property?.address?.lat!,
                      lng: +property!.address?.lon!,
                    }}
                  >
                    <LocationMarker
                      lat={+property?.address?.lat!}
                      lng={+property?.address?.lon!}
                    />
                  </GoogleMapReact> : ""
            }

          </div>
        </div>

        {/* Connectivity */}
        {
          searchParams.university !== null &&
          <div className="text-center space-y-2">
            <div className="flex items-center">
              <i className="las la-subway text-3xl mr-2"></i> Transit: {drivingDuration} minutes
            </div >
            <div className="flex items-center">
              <i className="las la-walking text-3xl mr-2"></i> Walking: {walkingDuration} minutes
            </div>
            <div className="flex items-center">
              <i className="las la-road text-3xl mr-2"></i> Distance: {drivingDistance} km
            </div>
          </div >
        }
      </div >
    );
  };

  const renderImportantNotes = () => {
    return (
      property?.description ?
        (
          <div className="listingSection__wrap" >
            <h2 className="text-2xl font-semibold">Important Notes</h2>
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
            <p>{property?.description}</p>
          </div >
        )
        : ""

    );
  };

  const renderEstimatedMoveInPayments = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Estimated Move-in Payments</h2>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
        <p>
          To make sure you can move into the place, you need to pay some
          standard fees during and after the application process.
        </p>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
        <p>
          The first month rent is paid when you apply or right after you get
          approval from the landlord.
        </p>
        <div className="flex w-3/4 md:w-1/2 justify-between">
          <p className="font-semibold mr-20">First Month Rent</p>
          <span className="font-semibold">
            {`${formatCurrency(property?.rentInformation?.firstMonth!)}`}
          </span>
        </div>
        <p>
          Once the landlord approves your application, you'll need to pay the
          following fees. Keep in mind that Landing can help you save money on
          the broker fee.
        </p>
        <div className="flex w-3/4 md:w-1/2 justify-between">
          <p className="font-semibold mr-20">Last Month Rent</p>
          <span className="font-semibold">
            {`${formatCurrency(property?.rentInformation?.lastMonth!)}`}
          </span>
        </div>
        <div className="flex w-3/4 md:w-1/2 justify-between">
          <p className="font-semibold mr-20">Security Deposit</p>
          <span className="font-semibold">
            {`${formatCurrency(property?.rentInformation?.securityDeposit!)}`}
          </span>
        </div>
        <div className="flex w-3/4 md:w-1/2 justify-between text-primary-green">
          <p className="font-semibold mr-20">Broker Fee</p>
          <span className="font-semibold">
            {
              property?.externalId?.startsWith("YGL") ?
                property?.rentInformation?.brokerFees === 0 ? "$0" : formatCurrency(property?.rentInformation?.brokerFees!)
                :
                formatCurrency(property?.rent!)
            }
          </span>
        </div>
      </div>
    );
  };

  const renderImagesSection = (imageLinks: string[]) => {
    return (
      <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={() => handleOpenModal(0)}
          >
            <NcImage
              containerClassName="absolute inset-0"
              className="object-cover w-full h-full rounded-md sm:rounded-xl"
              src={imageLinks[0]}
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {imageLinks.map((imageLink, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${index > 3 ? "hidden" : index === 3 ? "hidden md:block" : "" // Show 4 images in desktop & 3 images in mobile
                }`}
            >
              <NcImage
                containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                className="object-cover w-full h-full rounded-md sm:rounded-xl "
                src={imageLink}
              />

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={() => handleOpenModal(index + 1)}
              />
            </div>
          ))}
          {imageLinks.length > 1 && (
            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>

              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          )}
        </div>
      </header>
    );
  };

  const renderCalendlyContent = () => {
    const customMessage = `I am interested in scheduling a tour for ${property && property?.address?.line1 && property?.address?.line1}`;
    const url = `https://calendly.com/abhishekbenke-landingrealty/studentslanding-tour?a1=${encodeURIComponent(customMessage)}`;
    return (
    <div>
      <InlineWidget url={url} />
    </div>
    );
  };

  const renderCalendlyTrigger = (openModal: Function) => (
     <ButtonSecondary onClick={() => openModal()} className="w-full mt-6">
      Take a Tour
    </ButtonSecondary>
  );


  return (
    <>
      <Helmet>
        <meta
          name={`${property && property.id}`}
          content={`${property && property?.address?.line1 && property?.address?.line1
            } | Students Landing`}
        ></meta>
        <title>{`${property && property?.address?.line1 && property?.address?.line1
          } | Students Landing`}</title>
      </Helmet>
      <div
        className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
        data-nc-id="ListingStayDetailPage"
      >
        <>
          {renderImagesSection(
            images && images.length
              ? images.map((i) => i.link!)
              : [
                commingsoon
              ]
          )}
          {/* MODAL PHOTOS */}
          <ModalPhotos
            imgs={images && images.length ? images!.map((i) => i.link!) : []}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
          />
        </>
        {/* SINGLE HEADER */}
        <div className="container relative md:grid md:grid-cols-3 gap-5 my-16">
          <div className="col-span-2 relative rounded-md sm:rounded-xl">
            <div>
              {property ? (
                <main className="relative flex flex-col lg:flex-row">
                  {/* CONTENT */}
                  <div className="w-full space-y-8 lg:space-y-10 mb-5">
                    {renderTitleSection()}
                    {/* {renderDescriptionSection()} */}
                    {property.amenities ? renderAmentiesSection() : ""}
                    {property.description && renderImportantNotes()}
                    {renderEstimatedMoveInPayments()}
                    {/* {renderLocationSection()} */}
                  </div>

                  {/* SIDEBAR */}
                  {/* <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-28">{renderSidebar()}</div>
          </div> */}
                </main>
              ) : (
                ""
              )}
            </div>
          </div>
          <div>
            <div className="relative md:ml-5 sm:col-span-2">
              <div>
                <ButtonSecondary className="w-full" onClick={handleClick}>
                  {buttonText}
                </ButtonSecondary>
              </div>
              <div>
                <ButtonSecondary className="w-full mt-6" onClick={handleShortlist}>
                  {userAccount.likedListings?.some(
                    (listing) => listing.id === property?.id
                  ) ? (
                    <div className="flex justify-center items-center space-x-2">
                      <FaBookmark className="text-red-500" />
                      <h1>Shortlisted</h1>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center space-x-2">
                      <FaRegBookmark />
                      <h1>Shortlist</h1>
                    </div>
                  )}
                </ButtonSecondary>
              </div>
              <div>
                <NcModal
                  renderContent={renderCalendlyContent}
                  renderTrigger={renderCalendlyTrigger}
                  modalTitle="Schedule Your Tour"
                  contentExtraClass="max-w-screen-lg"
                  contentPaddingClass="p-0" // Removes extra padding to better fit the widget
                />
  
              </div>
              <div></div>
              <div>
                <ButtonPrimary
                  onClick={() => {
                    navigate(`/listing-detail/${id}/rental-application`);
                  }}
                  className="w-full my-6"
                >
                  Apply Now
                </ButtonPrimary>
              </div>
              <div>{renderLocationSection()}</div>
            </div>
          </div>
        </div>

        {/* STICKY FOOTER MOBILE */}
        {!isPreviewMode && <MobileFooterSticky data={property} />}
      </div >
    </>
  );
};

export default ListingStayDetailPage;
